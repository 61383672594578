<nav [ngClass]="authLayout ? 'headerPadding' : 'header'"
	class="bg-white border-gray-200 rounded mb-[150px] lg:mb-[60px]">
	<div class="flex md:hidden pb-8 text-[#372121] leading-[16.41px]">
		<app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-2'"
			[text]="'text-[14px]'"></app-city-menu>
	</div>

	<div class="flex flex-wrap items-center justify-between mx-auto">
		<div class="flex">
			<a (click)="goToHomeClearFilters($event)" class="flex items-center cursor-pointer">
				<img src="../../../../assets/icons/logo.svg" class="h-16" alt="Logo" />
			</a>
		</div>
		<ng-container *ngIf="show">
			<!-- Menú Escritorio -->
			<div class="flex flex-wrap items-end flex-col">

				<div class="hidden md:flex pb-8 text-[#372121] leading-[18.75px]">
					<app-city-menu (citySelected)="onCitySelected($event)" [space]="'space-x-3'"
						[text]="'text-[16px]'"></app-city-menu>
				</div>

				<div class="flex items-center md:order-2 ml-3.5 md:hidden">
					<ln-translate [showText]="false" class="mr-3 " />
					<button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (click)="showMenuMobile()">
						<mat-icon aria-hidden="false" aria-label="Example home icon">{{ icon }}</mat-icon>
					</button>
				</div>
				<div class=" items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
					<ul class="flex flex-col mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row 
                     md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
						<li>
							<ln-translate />
						</li>
						<a routerLink="/services"
							class="text-color block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
							aria-current="page">{{'header.linkServices' | translate}}
						</a>
						<a routerLink="/blog"
							class="text-color block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
							aria-current="page">{{'header.linkBlog' | translate}}
						</a>
						<a routerLink="/us"
							class="text-color block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
							aria-current="page">{{'headerMenu.textUs' | translate}}
						</a>
						<li *ngIf="isLogedin">
							<div class="flex" (mouseenter)="onHover()" (mouseleave)="onHoverOut()">
								<a (click)="toggleSubmenu()"
									class="text-color block py-2 pl-3 pr-8 a-secondary-color rounded md:bg-transparent md:p-0"
									aria-current="page">{{'header.linkMyAccount' | translate}}</a>
								<span *ngIf="!hasDocumentImage" class="alert-icon relative">
									<img src="../../../../../assets/icons/field-alert.svg">
								</span>
								<ul *ngIf="showSubmenu" id="submenu"
									class="fixed right-8 mt-2 rounded-md shadow-lg bg-white p-4 z-10"
									[ngClass]="esp ? 'spanish' : 'english'">
									<!-- Submenú -->
									<li class="flex items-center my-2 pl-4 text-left group">
										<div class="flex items-center">
											<a [routerLink]="['my-account/my-data']"
												class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
												aria-current="page">
												{{ 'header.linkMyData' | translate }}
											</a>
											<span *ngIf="!hasDocumentImage" class=" ml-1 relative"
												(mouseenter)="showAlert = true" (mouseleave)="showAlert = false">
												<img src="../../../../../assets/icons/field-alert.svg">
												<div [ngClass]="esp ? 'spanish-tooltip' : 'english-tooltip'">
													<div *ngIf="showAlert"
														class=" ml-4 tooltip border border-gray-200 rounded-md shadow-md z-100">
														<!-- Mensaje de alerta -->
														<p class="text-tooltip text-sm text-white">{{
															'header.textTooltip' | translate }}</p>
													</div>
												</div>
											</span>
										</div>
									</li>
									<li *ngIf="!isLogedin" class="my-2 pl-4 text-left">
										<a [routerLink]="['sessions/register']"
											class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkCreateAccount' | translate}}
										</a>
									</li>
									<li class="my-2 pl-4 text-left" (click)="goToMyReservations()">
										<a class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkMyReserves' | translate}}
										</a>
									</li>

									<li class="my-2 pl-4 text-left" (click)="goToFavorites()">
										<a class="text-color block py-2 pl-2 pr-4 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkMyFavorites' | translate}}
										</a>
									</li>
								</ul>
							</div>

						</li>
						<li *ngIf="!isLogedin" class="relative">

							<div (mouseenter)="onHover()" (mouseleave)="onHoverOut()">
								<a (click)="toggleSubmenu()"
									class="text-color block py-2 pl-3 pr-8 a-secondary-color rounded md:bg-transparent md:p-0"
									aria-current="page">{{'header.linkCreateAccount' | translate}}</a>
								<!-- Submenú -->
								<ul *ngIf="showSubmenu " id="submenu"
									[ngClass]="esp ? 'spanish-create' : 'english-create'"
									class="fixed right-8 mt-2 w-40 rounded-md shadow-lg bg-white p-2 z-10">
									<li *ngIf="!isLogedin" class="my-2 pl-4 text-left">
										<a routerLink="sessions/register"
											class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkCreateAccount' | translate}}
										</a>
									</li>
									<li class="my-2 pl-4 text-left">
										<a (click)="goToMyReservations()"
											class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkMyReserves' | translate}}
										</a>
									</li>
									<li class="my-2 pl-4 text-left">
										<a (click)="goToFavorites()"
											class="text-color block py-2 pl-2 pr-4 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
											aria-current="page">{{'header.linkMyFavorites' | translate}}
										</a>
									</li>
								</ul>
							</div>

						</li>
						<li *ngIf="!isLogedin">
							<a [routerLink]="['sessions/signin']"
								class="text-color a-secondary-color block py-2 pl-3 pr-4 roundedmd:bg-transparent md:p-0">
								{{'header.linkLogin' | translate}}</a>
						</li>
						<li *ngIf="isLogedin">
							<a (click)="openDialogCloseSession()"
								class="text-color a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
								{{'header.linkLogout' | translate}}</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- Menú Mobile -->
			<mat-menu #menu="matMenu" class="custom-menu">
				<ng-container *ngIf="!isLogedin">
					<button class="option" mat-menu-item [routerLink]="'sessions/register'">
						{{ 'headerMenu.textSignIn' | translate }}
					</button>
					<button class="option" mat-menu-item (click)="goToMyReservations()">
						{{ 'headerMenu.textMyReservations' | translate }}
					</button>
					<button class="option" mat-menu-item (click)="openDialogCreateAccount()">
						{{ 'headerMenu.textMyFavourites' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'services'">
						{{ 'headerMenu.textServices' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'blog'">
						{{ 'headerMenu.textBlog' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'us'">
						{{ 'headerMenu.textUs' | translate }}
					</button>
					<button class="optionAction" mat-menu-item [routerLink]="'sessions/signin'">
						{{ 'headerMenu.textLogIn' | translate }}
					</button>
				</ng-container>

				<ng-container *ngIf="isLogedin">
					<button mat-menu-item [routerLink]="'my-account/my-data'">
						<div class="firstButton option">
							<span>
								{{ 'headerMenu.textMyAccount' | translate }}
							</span>
							<span class="ml-1 w-fit" (click)=" toggleMessage($event); tooltip.toggle(); "
								(mouseenter)="toggleTooltip()" (mouseleave)="toggleTooltip()" #tooltip="matTooltip"
								matTooltip="{{ hoverText }}" matTooltipClass="custom-tooltip" matTooltipShowDelay="0"
								matTooltipHideDelay="200">
								<img src="../../../../../assets/icons/field-alert.svg">
							</span>
						</div>
					</button>

					<button class="option" mat-menu-item (click)="goToMyReservations()">
						{{ 'headerMenu.textMyReservations' | translate }}
					</button>
					<button class="option" mat-menu-item (click)="goToFavorites()">
						{{ 'headerMenu.textMyFavourites' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'services'">
						{{ 'headerMenu.textServices' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'blog'">
						{{ 'headerMenu.textBlog' | translate }}
					</button>
					<button class="option" mat-menu-item [routerLink]="'us'">
						{{ 'headerMenu.textUs' | translate }}
					</button>
					<button class="optionAction" mat-menu-item (click)="openDialogCloseSession()">
						{{ 'headerMenu.textLogOut' | translate }}
					</button>
				</ng-container>
			</mat-menu>
		</ng-container>
	</div>
</nav>