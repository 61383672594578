export interface City {
    id: number;
    name: string;
    paisImage: string
  }
  
  export const cities: City[] = [
    { id: 1, name: 'Guanacaste', paisImage: '../../assets/countryIcons/costaRica.webp'},
    { id: 2, name: 'La Fortuna', paisImage: '../../assets/countryIcons/costaRica.webp'},  
    { id: 3, name: 'Antigua Guatemala', paisImage: '../../assets/countryIcons/guatemala.webp' },
    { id: 4, name: 'Miami', paisImage: '../../assets/countryIcons/usa.webp'},
    { id: 5, name: 'Oaxaca', paisImage: '../../assets/countryIcons/mexico.webp'},
    { id: 6, name: 'Tulum', paisImage: '../../assets/countryIcons/mexico.webp'},
  ];