import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';

import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from '../../services/auth.service';
import { BreakPointService } from '../../services/break-point.service';
import { LnDialogComponent } from '../ln-dialog/ln-dialog.component';
import { TranslationService } from '../../services/translation.service';
import { DialogService } from '../../services/dialog.service';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { CityService } from './services/city.service';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {

	@Input() show: boolean = true;
	@Input() authLayout: boolean = false;
	public showMenu: boolean = false;
	icon = 'menu';
	isLogedin = false;
	subscriptions: Subscription[] = [];
	showSubmenu = false;
	showAlert = true;
	currentLanguage = "es";
	esp: boolean = true;
	hoverText: string = '';
	tooltipOpen = false;
	primeraVezTooltip = false;
	hasDocumentImage = false;
	activeCity: number | null = null;


	constructor(
		private _dialog: MatDialog,
		private router: Router,
		private firebaseService: FirebaseService,
		private auth: AuthService,
		public breakPointService: BreakPointService,
		private _translationService: TranslationService,
		private _dialogService: DialogService,
		private _storeFilterService: StoreFiltersService,
		private cityService: CityService,
		private eRef: ElementRef

	) {
		this.cityService.selectedCity$.subscribe(cityId => {
			this.activeCity = cityId;
		});

		this._translationService.onLangChange.subscribe(() => {//este suscribe se ejecuta cada vez que se cambia el idioma
			this.currentLanguage = this._translationService.getCurrentLang;
			if (this.currentLanguage === 'en') {
				this.esp = false;
				this.hoverText = 'You are missing data to complete in the “My account” section.';
			}
			else {
				this.esp = true;
				this.hoverText = 'Te faltan datos por completar en la sección “Mi cuenta”.';
			}
			// Actualizar el texto del tooltip cuando cambia el idioma
		});

	}

	ngOnInit(): void {
		this.auth.documentImage()
		localStorage.getItem('hasImage') === 'true' ? this.hasDocumentImage = true : this.hasDocumentImage = false;

		this.currentLanguage = this._translationService.getCurrentLang;
		if (this.currentLanguage === 'en') {
			this.esp = false;
			this.hoverText = 'You are missing data to complete in the “My account” section.';
		}

		else {
			this.esp = true;
			this.hoverText = 'Te faltan datos por completar en la sección “Mi cuenta”.';
		}


		const subscription = this.firebaseService
			.isAuthenticatedUser()
			.subscribe((user) => {
				this.isLogedin = user;
				this.auth.isLogedin = user;
			});

		this.subscriptions.push(subscription);
		this.hoverText = this.currentLanguage === 'es' ? 'Te faltan datos por completar en la sección “Mi cuenta”.' : 'You are missing data to complete in the “My account” section.';
	}

	onCitySelected(cityId: number) {
		this.activeCity = cityId;
	}

	@HostListener('document:click', ['$event'])
	onClickOutside(event: Event) {
		if (this.showMenu && !this.eRef.nativeElement.contains(event.target)) {
			this.showMenuMobile();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	public showMenuMobile() {

		this.icon = this.showMenu ? 'close' : 'menu';
	}

	toggleSubmenu() {
		this.showSubmenu = !this.showSubmenu;
	}

	public goToHome() {
		this.router.navigateByUrl('');
	}

	public signOut() {
		this.firebaseService.doLogout();
		localStorage.removeItem('hasImage');
		this.goToHome();
	}

	publishProperty() {
		this.router.navigateByUrl('services-form');
	}


	openDialogCloseSession() {
		this._dialog
			.open(LnDialogComponent, {
				width: '380px',
				data: {
					title: 'header.textConfirmCloseSession',
					buttonOk: 'header.textCloseSession',
					buttonCancel: 'header.textNoBack',
				},
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe((res) => {
				if (res) this.signOut();
			});
	}

	openDialogCreateAccount() {
		this._dialog
			.open(LnDialogComponent, {
				width: '571px',
				data: {
					title: 'dialogLoginRegistSession.textSignupOrLogin',
					subTitle: 'dialogLoginRegistSession.textCreate',
					buttonOk: 'header.linkLogin',
					buttonCancel: 'dialogLoginRegistSession.textRegister',
					account: true,
				},
			})
	}

	goToMyReservations() {
		this.showMenuMobile()
		if (this.auth.isLogedin) {
			this.router.navigateByUrl('my-reservations');

		} else {
			this._dialogService.openLoginRegistSessionDialog('', this.router.url)
		}
	}


	goToFavorites() {
		this.showMenuMobile()
		if (this.auth.isLogedin) {
			this.router.navigateByUrl('my-favorites');

		} else {
			this._dialogService.openLoginRegistSessionDialog('', this.router.url)
		}
	}

	private timer: any;

	onHover() {
		clearTimeout(this.timer); // Cancela cualquier temporizador existente
		if (!this.showSubmenu) { // Solo abre el menú si no está ya abierto
			this.toggleSubmenu();
		}
	}

	onHoverOut() {
		this.timer = setTimeout(() => {
			if (this.showSubmenu) { // Verifica si el menú está abierto antes de intentar cerrarlo
				this.toggleSubmenu(); // Cierra el menú
			}
		}, 500);
	}

	@ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event: Event) {
		if (this.menuTrigger.menuOpen) {
			this.menuTrigger.closeMenu(); // Cierra el menú si está abierto
		  }
	}

	closeSubmenu() {
		if (this.showSubmenu) {
			this.toggleSubmenu();
		}
	}

	toggleMessage(event: Event) {
		event.stopPropagation();
		if (this.currentLanguage === 'es') {
			this.hoverText = 'Te faltan datos por completar en la sección “Mi cuenta”.';
		} else if (this.currentLanguage === 'en') {
			this.hoverText = 'You are missing data to complete in the “My account” section.';
		} else {
			this.hoverText = '';
		}

	}
	toggleTooltip() {
		if (this.currentLanguage === 'es') {
			this.hoverText = 'Te faltan datos por completar en la sección “Mi cuenta”.';
		} else if (this.currentLanguage === 'en') {
			this.hoverText = 'You are missing data to complete in the “My account” section.';
		} else {
			this.hoverText = '';
		}
	}



	goToHomeClearFilters(event: Event) {
		event.preventDefault();
		window.location.href = '/';
		this._storeFilterService.clearAllFilters();
	}
	

	

}
