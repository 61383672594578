import { Mapper } from "../../core/base/mapper";
import { AlojamientosResponseServer } from "../entity/mapa/alojamientos-filters-entity";
import { AlojamientosResponseModel } from "../../core/domain/mapa/alojamiento-filters-model";
import { environment } from "../../../environments/environment";
import { ImagenesModel } from "../../core/domain/mapa/imagenes-model";


export class AlojamientosFiltersMapper implements Mapper<AlojamientosResponseServer, AlojamientosResponseModel> {

	constructor() { }

	mapFrom(entity: AlojamientosResponseServer): AlojamientosResponseModel {
		const alojamiento = entity.alojamientos.map(alojamiento => {
			const permiteMascotas = alojamiento.propiedad && alojamiento.propiedad[0]
				? Boolean(Number(alojamiento.propiedad[0].permiteMascotas) ?? 0)
				: false;
			const pics: ImagenesModel[] = [];
			if (alojamiento.imagenes.length > 0 && alojamiento.imagenes) {
				alojamiento.imagenes.forEach(elm => {
					const urlParts = elm.url.split('/');
					const fileName = urlParts.pop();
					const fileBase = urlParts.join('/');
					pics.push(<ImagenesModel><unknown>{
						idImagen: elm.idImagen,
						posicion: elm.posicion,
						url: {
							sm: environment.firebaseConfig.storageBucket + "/" + fileBase + "/sm/" + fileName,
							md: environment.firebaseConfig.storageBucket + "/" + fileBase + "/md/" + fileName,
							lg: environment.firebaseConfig.storageBucket + "/" + fileBase + "/lg/" + fileName
						}
					})
				});
			}
			// const descuentos:Descuentos[]=[];
			// alojamiento.ofertas.map(v=>{console.log()})
			const objetos = Array.isArray(alojamiento.catalogoObjeto) ? alojamiento.catalogoObjeto.map(objeto => ({
				descripcion: objeto.descripcion,
				idObjeto: objeto.idObjeto,
			})) : [];
			return {
				idAlojamiento: alojamiento.idAlojamiento,
				idPropiedad: alojamiento.idPropiedad,
				nombre: alojamiento.nombre,
				maximoHuespedes: alojamiento.maximoHuespedes,
				tarifaBase: alojamiento.tarifaBase,
				propiedad: alojamiento.propiedad ? alojamiento.propiedad.map(propiedad => ({
					nombre: propiedad.nombre,
					direccion: propiedad.direccion,
					ciudad: propiedad.ciudad,
					tipoPropiedad: propiedad.tipoPropiedad,
					latitud: propiedad.latitud,
					longitud: propiedad.longitud,
					permiteMascotas: permiteMascotas,
					cantidadParking: propiedad.cantidadParking,
					distanciaAlCentroParsed: propiedad.distanciaAlCentroParsed,
				})) : [],
				PrecioCalculado: alojamiento.PrecioCalculado || 0,
				totalBase: alojamiento.totalBase || 0,
				PromedioNoches: alojamiento.PromedioNoches || 0,
				tarifas: alojamiento.tarifas ? alojamiento.tarifas.map(tarifa => ({
					fechaDesde: tarifa.fechaDesde,
					fechaHasta: tarifa.fechaHasta,
					precioNoche: tarifa.precioNoche,
				})) : [],

				imagenes: pics,
				objetos: objetos,
				ambientes: Array.isArray(alojamiento.ambientes)
					? alojamiento.ambientes.map(ambiente => ({

						codTipoAmbiente: ambiente.codTipoAmbiente,
						descripcion: ambiente.descripcion,
						esCompartido: ambiente.esCompartido,
					}))
					: [],
				favorito: parseInt(alojamiento.favorito),
				cantidadBanos: alojamiento.cantidadBanos,
				cantidadCamas: alojamiento.cantidadCamas,
			};
		}
		);
		return {
			totalAlojamientos: entity.totalAlojamientos,
			alojamientos: alojamiento
		};
	}




	mapFromList(entities: AlojamientosResponseServer[]): AlojamientosResponseModel[] {
		return entities.map(entity => this.mapFrom(entity));
	}

	mapTo(param: AlojamientosResponseModel): AlojamientosResponseServer {
		throw new Error("Method not implemented.");
	}
	mapToList(param: AlojamientosResponseModel[]): AlojamientosResponseServer[] {
		throw new Error("Method not implemented.");
	}

}
