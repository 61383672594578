import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, inject, input } from '@angular/core';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceHintComponent } from '../service-hint/service-hint.component';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { Subject, take, takeUntil } from "rxjs";

import { Bloqueos } from '../../interfaces/bloqueos.interface';
import { OfertaModel, TarifaModel } from '../../../core/domain/property-detail-post-model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-booking-card',
	templateUrl: './booking-card.component.html',
	styleUrl: './booking-card.component.css'
})
export class BookingCardComponent implements OnDestroy {

	private destroy$ = new Subject<void>();
	@Input() propertyId: string = "";
	@Input() maxGuests: number = 50;
	@Input() oferta: number = 0;
	@Input() tarifaBase: number = 1;
	@Input() arrayTarifas: TarifaModel[] = [];

	@Input() fechasBloqueo: Bloqueos[] = [];
	@Input() tarifaLimpieza: number = 1;
	@Input() mascotas: boolean = false;

	@Input() promedioNoches: number = 0;
	@Input() noches: number = 0;
	@Input() tarifaLunaNueva: number = 0;
	@Input() totalNoches: number = 0;
	@Input() tarifaTotal: number = 0;
	@Input() tieneOferta = false;

	@Output() dateRangeChanged = new EventEmitter<{ fromDate: string, toDate: string }>();
	@Output() guestChanged = new EventEmitter<{ Adults: string, Children: string, Infants: string, Pets: string }>();



	cantidadNoches: number = 1;
	textBooking: string = '';
	precioTotalNoches: number = 0;
	total: number = 0;
	porcentajeLunaNueva = 0.03;
	showAlert = false;
	esp: boolean = true;
	currentLanguage = "es";
	hoverText: string | undefined;
	fromDate: any;
	toDate: any;
	precioNocheOferta: number = 0;
	isLogin = this._firebaseService.isAuthenticatedUser();
	totalConTodo: number = 0;


	Adults: number = 2;
	Pets: number = 0;
	Infants: number = 0;
	Children: number = 0;

	constructor(
		private storeFilters: StoreFiltersService,
		private _translationService: TranslationService,
		private _translateService: TranslateService,
		private _cdr: ChangeDetectorRef,
		private _router: Router,
		private _firebaseService: FirebaseService,
		private _auth: AuthService,
		private _dialogService: DialogService,

	) {
		_translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {

			this.currentLanguage = this._translationService.getCurrentLang;
			if (this.currentLanguage === 'en')
				this.esp = false;
			else {
				this.esp = true;
			}
			this.toggleMessage();
			this.getText();
		});


	}
	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
	ngOnInit(): void {

	}

	gotToReservation() {
		if (this._auth.isLogedin) {

			this._router.navigate(["reservation", this.propertyId], {
				//   queryParamsHandling: "merge",
			});
		} else {
			this._dialogService.openLoginRegistSessionDialog('', this._router.url)
				.pipe(take(1))
		}

	}

	onDateRangeChanged($event: any) {
		if ($event.fromDate !== null && $event.toDate !== null) {
			const from = this.convertNgbDateToDate($event.fromDate);
			this.fromDate = this.convertDateToString(from);

			const toDate = this.convertNgbDateToDate($event.toDate);

			this.toDate = this.convertDateToString(toDate);

			this.dateRangeChanged.emit({ fromDate: this.fromDate, toDate: this.toDate });
		}

		this._cdr.detectChanges();
	}

	handleChildrenChange($event: number) {
		this.Children = $event;
		this._cdr.detectChanges();

		this.guestChanged.emit({ Adults: this.Adults.toString(), Children: this.Children.toString(), Infants: this.Infants.toString(), Pets: this.Pets.toString() });
	}
	handleAdultsChange($event: number) {
		this.Adults = $event;
		this._cdr.detectChanges();
		this.guestChanged.emit({ Adults: this.Adults.toString(), Children: this.Children.toString(), Infants: this.Infants.toString(), Pets: this.Pets.toString() });
	}
	handlePetsChange($event: number) {
		this.Pets = $event;
		this._cdr.detectChanges();
		this.guestChanged.emit({ Adults: this.Adults.toString(), Children: this.Children.toString(), Infants: this.Infants.toString(), Pets: this.Pets.toString() });
	}
	handleInfantsChange($event: number) {
		this.Infants = $event;
		this._cdr.detectChanges();
		this.guestChanged.emit({ Adults: this.Adults.toString(), Children: this.Children.toString(), Infants: this.Infants.toString(), Pets: this.Pets.toString() });
	}

	getDateFormat(date: string) {
		const [year, month, day] = date.split('-').map(Number);
		return new Date(year, month - 1, day);
	}


	getText() {
		this._translationService.getTranslation('card.textBooking').subscribe((data) => {
			return this.textBooking = data;
		});
	}

	toggleMessage() {
		if (this.currentLanguage === 'es') {
			this.hoverText = 'En Luna Nueva destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes de Guatemala, brindándoles capacitación y soporte para promover sus productos alrededor del mundo.';
		} else if (this.currentLanguage === 'en') {
			this.hoverText = 'At Luna Nueva we allocate part of our resources to support artisans and independent artists from Guatemala, providing them with training and support to promote their products around the world.';
		} else {
			this.hoverText = undefined;
		}
	}


	private _matDialog = inject(MatDialog);
	showHint() {
		this._matDialog
			.open(ServiceHintComponent, {
				panelClass: 'app-service-hint',
			})
	}


	convertNgbDateToDate(ngbDate: NgbDate): Date {
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
	}

	convertDateToString(date: Date): string {
		return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
	}



}
