import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corousel',
  templateUrl: './corousel.component.html',
  styleUrl: './corousel.component.css'
})
export class CorouselComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef){}

  public images: { url: string; text1: string, text2: string, text3: string }[] = [
    { url: './../../../../assets/images/banner-home/Imagen1.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3' },
    { url: './../../../../assets/images/banner-home/Imagen2.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3' },
    { url: './../../../../assets/images/banner-home/Imagen3.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3' },
    { url: './../../../../assets/images/banner-home/Imagen4.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3' },
    { url: './../../../../assets/images/banner-home/Imagen5.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3' },
    { url: './../../../../assets/images/banner-home/ImagenCostaRica.webp', text1: 'carousel.textInBannerWebCostaRica1', text2: 'carousel.textInBannerWebCostaRica2', text3: 'carousel.textInBannerWebCostaRica3'},
    { url: './../../../../assets/images/banner-home/ImagenOaxaca.webp', text1: 'carousel.textInBannerWebOaxaca1', text2: 'carousel.textInBannerWebOaxaca2', text3: 'carousel.textInBannerWebOaxaca3' },
    { url: './../../../../assets/images/banner-home/ImagenTulum.webp', text1: 'carousel.textInBannerWebTulum1', text2: 'carousel.textInBannerWebTulum2', text3: 'carousel.textInBannerWebTulum3' },
    { url: './../../../../assets/images/banner-home/antiguaGuatemala.webp', text1: 'carousel.textInBannerWeb1', text2: 'carousel.textInBannerWeb2', text3: 'carousel.textInBannerWeb3'},
    // Agrega más objetos con URLs y textos según sea necesario
  ];

  public currentImageIndex: number = 0;

  ngOnInit(): void {
    this.startCarousel();
  }

  startCarousel() {
    setInterval(() => {
      this.showNextImage();
    }, 5000); // Cambia de imagen cada 5 segundos (5000 milisegundos)
  }
  
  showNextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    this.cdr.detectChanges(); // Detectar cambios manualmente después de cambiar la imagen
  }

}
