
<mat-form-field>
  <mat-label>{{'cityMenu.text' | translate}}</mat-label>
  <mat-select class="mt-[8px]" [(value)]="activeCity" (selectionChange)="onCityChange($event.value)">
    <mat-option *ngFor="let city of cities" [value]="city.id" [disabled]="city.id !== 3 && city.id !== 4">
      <div class="city-option-content">  <img *ngIf="city.paisImage" [src]="city.paisImage" alt="{{ city.name }} Flag" class="h-4 w-4">
        <span *ngIf="city.id !== 4">{{ city.name }}</span>
        <span *ngIf="city.id === 4">
          <a href="{{urlMiami}}" target="_blank" rel="noopener noreferrer" class="">
            {{ city.name }}
          </a>
        </span>
        
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>

