import { Mapper } from "../../core/base/mapper";
import { PropertyPostModel } from "../../core/domain/property-post-model";
import { PropertyBaseModel } from "../../core/domain/property-base-model";
import { PropertyTypeModel } from "../../core/domain/property-type-model";
import { PostPictureModel } from "../../core/domain/post-picture-model";
import { PropertyExtendedEntity } from "../entity/property-extended-entity";
import { environment } from "../../../environments/environment";
import { PropertyPostDetailEntity } from "../entity/poperty-detailed-post-entity";
import { PropertyDetailPostModel } from "../../core/domain/property-detail-post-model";
import { ImagenesModel } from "../../core/domain";

export class PropertyExtendedPosRepositoryMapper implements Mapper<PropertyPostDetailEntity, PropertyDetailPostModel> {
	iconUrl=environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/icons/'
	mapFrom(param: PropertyPostDetailEntity): PropertyDetailPostModel {
		const pics: ImagenesModel[] = [];
		

		if (param.imagenes.length > 0 && param.imagenes) {
			param.imagenes.forEach(elm => {
				const urlParts = elm.url.toString().split('/');
				const fileName = urlParts.pop();
				const fileBase = urlParts.join('/');
				pics.push(<ImagenesModel>{
					idImagen: elm.idImagen,
					posicion: elm.posicion,
					url: {
						sm: environment.firebaseConfig.storageBucket + "/" + fileBase + "/sm/" + fileName,
						md: environment.firebaseConfig.storageBucket + "/" + fileBase + "/md/" + fileName,
						lg: environment.firebaseConfig.storageBucket + "/" + fileBase + "/lg/" + fileName
					}
				})
			});
		}
		const propiedadesPoliticaArray = param.propiedades.propiedades_politicas;
		const politicas:any= [];

		if (propiedadesPoliticaArray) {
			propiedadesPoliticaArray.forEach(propiedad => {
				const url=environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/icons/'
				const iconoUrl = propiedad.politicas.icono ? this.iconUrl + propiedad.politicas.icono : url+'generico.svg';
				if ((propiedad.politicas.textoPolitica.toLowerCase().includes('checkout') || propiedad.politicas.textoPolitica.toLowerCase().includes('check out'))) {
					propiedad.politicas.textoPolitica = propiedad.politicas.textoPolitica+' '+param.propiedades.checkOut;
					propiedad.politicas.textoPoliticaEng = propiedad.politicas.textoPoliticaEng+' '+param.propiedades.checkOut;
				}
				if (propiedad.politicas.textoPolitica && (propiedad.politicas.textoPolitica.toLowerCase().includes('checkin') || propiedad.politicas.textoPolitica.toLowerCase().includes('check in'))) {
					propiedad.politicas.textoPolitica = propiedad.politicas.textoPolitica+' '+param.propiedades.checkIn;
					propiedad.politicas.textoPoliticaEng = propiedad.politicas.textoPoliticaEng+' '+param.propiedades.checkIn;
				}
				politicas.push({
					idPolitica: propiedad.politicas.idPolitica,
					textoPolitica: propiedad.politicas.textoPolitica,
					textoPolitica_eng: propiedad.politicas.textoPoliticaEng,
					icono: iconoUrl
				});
			});
		}
		const serviciosArray = param.alojamientos_tiposServicio;
		const serviciosConst:any= [];

		if (serviciosArray) {
			serviciosArray.forEach(servicios => {
				const url=environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/icons/'
				const iconoUrl = servicios.tiposServicio.icono ? this.iconUrl + servicios.tiposServicio.icono : url+'generico.svg';
				serviciosConst.push({
					descripcion: servicios.tiposServicio.descripcion,
					descripcionEng: servicios.tiposServicio.descripcionEng,
					icono: iconoUrl,
					costoAdicional: servicios.costoAdicional,
				
				});
			});
		}
		const areasComunuesArray = param.propiedades.propiedades_areasComunes;
        const areasComunesConst: any[] = [];

        if (areasComunuesArray) {
            areasComunuesArray.forEach(i => {
                const url = environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/icons/';
                const iconoUrl = i.areasComunes.icono ? this.iconUrl + i.areasComunes.icono : url + 'generico.svg';
                areasComunesConst.push({
                    descripcion: i.areasComunes.descripcion,
                    descripcionEng: i.areasComunes.descripcionEng,
                    icono: iconoUrl,
                });
            });
        }


		return {
			idAlojamiento: param.idAlojamiento,
			idPropiedad: param.idPropiedad,
			codTipoAlojamiento: param.codTipoAlojamiento,
			esCompleto: param.esCompleto,
			descripcion: param.descripcion,
			descripcion_eng: param.descripcion_eng,
			createdAt: param.createdAt,
			updatedAt: param.updatedAt,
			areaTotal: param.areaTotal,
			nombre: param.nombre,
			nombre_eng: param.nombre_eng,
			maximoHuespedes: param.maximoHuespedes,
			tarifaLimpieza: param.tarifaLimpieza,
			cantidadBanos: param.cantidadBanos,
			cantidadCamas: param.cantidadCamas,
			cantidadHuespedes: param.cantidadHuespedes,
			tour360: param.tour360,
			tarifaBase: param.tarifaBase,
			alojamientos_tiposServicio: serviciosConst,
			tarifas: param.tarifas.map(tarifa => ({
				fechaDesde: tarifa.fechaDesde,
				fechaHasta: tarifa.fechaHasta,
				precioNoche: tarifa.precioNoche,
			})),
			ofertas: param.ofertas ? param.ofertas.map(oferta => ({
				porcentajeDescuento: oferta.porcentajeDescuento,
				fechaDesde: oferta.fechaDesde,
				fechaHasta: oferta.fechaHasta
			})) : [],
			imagenes: pics,
			favoritos: param.favoritos,
			favorito: param.favorito,
			propiedades: {
				direccion: param.propiedades.direccion,
				ciudad: {
					nombre: param.propiedades.ciudad.nombre,
					pais: {
						nombre: param.propiedades.ciudad.pais.nombre
					},
				},
				latitud: param.propiedades.latitud,
				longitud: param.propiedades.longitud,
				tipoPropiedad: {
					descripcion: param.propiedades.tipoPropiedad.descripcion
				},
				propiedades_areasComunes: areasComunesConst,
				distanciaAlCentro: param.propiedades.distanciaAlCentro,
				permiteMascotas: param.propiedades.permiteMascotas,
				informacionZona: param.propiedades.informacionZona,
				informacionZona_eng: param.propiedades.informacionZona_eng,
				checkIn: param.propiedades.checkIn,
				checkOut: param.propiedades.checkOut,
				smokeArea: param.propiedades.smokeArea,
				party: param.propiedades.party,
				loudMusic: param.propiedades.loudMusic,
				addicional: param.propiedades.addicional,
				ascensor: param.propiedades.ascensor,
				orientacion: param.propiedades.orientacion,
				desayuno: param.propiedades.desayuno,
				propiedades_politicas: politicas,
				nombre: param.propiedades.nombre,
				cantidadParking: param.propiedades.cantidadParking
			},
			ambientes: param.ambientes.map(ambiente => ({
				idAmbiente: ambiente.idAmbiente,
				codTipoAmbiente: ambiente.codTipoAmbiente,
				objetos: ambiente.objetos.map(objeto => ({
					idObjeto: objeto.idObjeto,
					esVisible: objeto.esVisible,
					catalogoObjetos: {
						idCatalogoObjeto: objeto.catalogoObjetos.idCatalogoObjeto,
						descripcion: objeto.catalogoObjetos.descripcion,
						descripcionEng: objeto.catalogoObjetos.descripcionEng,
						icono: objeto.catalogoObjetos.icono ? this.iconUrl + objeto.catalogoObjetos.icono : this.iconUrl + 'generico.svg',
					}
				})),
				tiposAmbiente: {
					descripcion: ambiente.tiposAmbiente.descripcion,
					descripcionEng: ambiente.tiposAmbiente.descripcionEng,
					esCompartido: ambiente.tiposAmbiente.esCompartido,
					icono: ambiente.tiposAmbiente.icono ? this.iconUrl + ambiente.tiposAmbiente.icono : this.iconUrl + 'generico.svg',
				}
			})),
			bloqueos: param.bloqueos

		};
	}

	mapFromList(param: PropertyPostDetailEntity[]): PropertyDetailPostModel[] {
		const postModels: PropertyDetailPostModel[] = [];
		return postModels;
	}

	mapTo(param: PropertyDetailPostModel): PropertyPostDetailEntity {
		return <PropertyPostDetailEntity>{}
	}

	mapToList(param: PropertyDetailPostModel[]): PropertyPostDetailEntity[] {
		return [];
	}

}
