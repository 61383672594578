
<span class=" pl-5 pr-5">
  <h1 class="title">{{ "home.bannerFirst" | translate }}</h1>
  <div class="lineMobile"></div>
</span>

<mat-accordion>
  <mat-expansion-panel class="matExpansionPannel" hideToggle [(expanded)]="xpandStatus">
    <mat-expansion-panel-header class="matHeader">
      <mat-panel-title >
        <span  class="subtitle">{{ "home.bannerSecond" | translate }}  </span>
        
        <mat-panel-description>
          <img *ngIf="!xpandStatus" src="../../../../assets/icons/arrow-down.svg" alt="" />
          <img class="rotate180" *ngIf="xpandStatus" src="../../../../assets/icons/arrow-down.svg" alt="" />
        </mat-panel-description>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="content">
      <div class="lineSearch mb-5"></div>
      <div class="textMobile flex flex-col margin">
        <h4 class="overflow titleSearchMobile">
          {{ "searchBar.textDateTitle" | translate }}
        </h4>
        <div class="flex flex-col margin">
          
          <app-ln-date-picker (dateRangeSelected)="onDateRangeSelected($event)" [fromDate]="this.fromDate" [toDate]="this.toDate"
          (resetChange)="handleResetChange($event)" [reset]="this.reset"></app-ln-date-picker>
		  <h4 class="overflow subtitleSearchMobile">
			{{ "searchBar.textDateTitle" | translate }}
		  </h4>
      </div>
      </div>
      <div class="lineSearch mb-5"></div>
      <div class="flex flex-col margin">
        <h4 class="overflow titleSearchMobile">
          {{ "guestSelector.textTitle" | translate }}
        </h4>
        <app-ln-guest-selector [adults]="Adults" [pets]="Pets" [infants]="Infants"
            [children]="Children" (adultsChange)="handleAdultsChange($event)"
            (petsChange)="handlePetsChange($event)" (infantsChange)="handleInfantsChange($event)"
            (childrenChange)="handleChildrenChange($event)"
            (resetChange)="handleResetChange($event)" [reset]="this.reset" [mascotas]="true">
        </app-ln-guest-selector >
      </div>
      <div class="lineSearch mb-5"></div>
      <div class="flex flex-col margin">
        <h4 class="overflow titleSearchMobile">{{ 'searchBar.labelPriceRange' | translate }}</h4>
        <app-ln-price-slider (selectedMinValueEvent)="setMinValue($event)"
            (selectedMaxValueEvent)="setMaxValue($event)" [changedDefaultValues]=changedDefaultValues
            [valuesReceived]="sendPriceValues()" [startThumbValueInput]="minPrice" [startThumbValue]="minPrice"
            [endThumbValue]="maxPrice" [endThumbValueInput]="maxPrice" >
        </app-ln-price-slider>
      </div>

      <div class="lineSearch mb-5"></div>

      <div class="flex">
        <div class="filterModalContainer" (click)="showModalFilter()">
          <button class="filterModalButton">
            <h4>{{'searchBar.filters' | translate }}</h4>
            <img src="../../../../assets/icons/filters.svg" alt="">
          </button>
        </div>
        <app-clear-button *ngIf="changedDefaultValues" (click)="clearFilters()"/>
      </div>

      <div class="lineSearch" style="margin-bottom: 0px !important;"></div>


      <div class="grid searchDivMobile">
        <div class="button-container">
          <ln-button
            [color]="'warn'"
            [icon]="'search'"
            [text]="'searchBar.btnSearch' | translate"
            (click)="search()"
          >
          </ln-button>
          <ln-button [color]="'accent'" [text]="'searchBar.btnSearchInMap' | translate"
            (clickButton)="goToSearchInMap()">
          </ln-button>
        </div>
      </div>

      <!-- AQUI -->
      <div class="slider">
        <p>{{ "searchOption.textTitle" | translate }}</p>
        <div class="toggleButtonsContainer">
          <app-clear-button (click)="clearType()" *ngIf="fontStyle" />
          <mat-button-toggle-group [(ngModel)]="fontStyle" aria-label="Font Style" class="swiper-search">
            <div class="swiper-wrapper">
              <mat-button-toggle value="BedBreakfast" class="swiper-slide" [disabled]="isPetSelected" (click)="[removeDisabledParams(), setTypeFilter('BedBreakfast')]">
                <img src="./../../../../assets/icons/cofeeIcon.svg" alt="Hotel" />
                <p [class.disabled-button]="isPetSelected">{{ "searchOption.textBed&Breakfast" | translate }}</p>
              </mat-button-toggle>
              <mat-button-toggle value="Apartamentos" class="swiper-slide" (click)="[removeDisabledParams(), setTypeFilter('Apartamentos')]">
                <img src="./../../../../assets/icons/apartmentIcon.svg" alt="Apartamento" />
                <p>{{ "searchOption.textApartment" | translate }}</p>
              </mat-button-toggle>
              <mat-button-toggle value="Casas" class="swiper-slide" (click)="[removeDisabledParams(), setTypeFilter('Casas'),]">
                <img src="./../../../../assets/icons/homeIcon.svg" alt="Casa" />
                <p>{{ "searchOption.textHouse" | translate }}</p>
              </mat-button-toggle>
              <mat-button-toggle value="Suite" class="swiper-slide" (click)="[ removeDisabledParams(),setTypeFilter('Suite'),]">
                <img src="./../../../../assets/icons/suite.svg" alt="Suite" />
                <p>{{ "card.labelSuite" | translate }}</p>
              </mat-button-toggle>
            </div>
          </mat-button-toggle-group>
        </div>
        <div class="flex h-full">
          <div class="swiper-pagination-search"></div>
        </div>
      </div>
      <!-- AQUI -->

      <div class="closeMenu flex">
        <button class="pt-4 pb-3" (click)="xpandStatus = false">
          <img
            class="rotate180"
            *ngIf="xpandStatus"
            src="../../../../assets/icons/arrow-down.svg"
            alt=""
          />
        </button>
      </div>

    </div>
  </mat-expansion-panel>
</mat-accordion>