import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { PropertyDetailPostModel } from '../../../core/domain/property-detail-post-model';
import { ObjetosEntity } from '../../../data/entity/mapa/objetos-entity';


@Component({
	selector: 'app-reservartion-card',
	templateUrl: './reservartion-card.component.html',
	styleUrls: [
		'./reservartion-card.component.css',
		'./2reservation-card.component.css',
	],
})
export class ReservartionCardComponent implements OnInit, OnChanges {
	/*** Estado de carga ***/
	isLoaded = false;

	/*** Información del alojamiento ***/
	accomodationType: string = '';
	bedType: string = '';
	bathroomType: string = '';
	cantidadHuespedes: number = 0;

	/*** Información sobre la habitación y camas ***/
	@Input() bedTypes!: any[];

	/*** Información de la tarjeta ***/
	@Input() card!: PropertyDetailPostModel;
	@Input() definePrice!: (price: string) => void;

	/*** Información de fechas ***/
	@Input() noches: number = 0; // Se asigna con: data.noches
	cantidadNoches: number = 1;
	fromDate: any;
	toDate: any;

	/*** Información sobre precios ***/

	precioNocheOferta: number = 0;
	precioTotalNoches: number = 0;
	precioPorNoche: number = 0;
	total: number = 0;
	totalConTodo: number = 0;

	/*** Tarifas y descuentos ***/
	@Input() tarifaLimpieza: number = 0; // Se asigna con: data.tarifaLimpieza
	@Input() tarifaLunaNueva: number = 1; // Se asigna con: data.tarifaLunaNueva
	@Input() tieneOferta: boolean = false; // Se asigna con: data.tarifaBase > data.promedioNoches

	/*** Variables adicionales de tarifas ***/
	@Input() promedioNoches: number = 0;
	@Input() totalNoches: number = 0;
	@Input() tarifaTotal: number = 0;


	/*** Información del usuario ***/
	isFavorite: boolean = false;
	showDescription: boolean = false;


	/*** Información de ocupantes ***/
	Adults: number = 0;
	Pets: number = 0;
	Infants: number = 0;
	Children: number = 0;

	/*** Rango de valores (posible slider o filtro) ***/
	rangeValues = '';
	cantidadHabitaciones = 0;
	queenBedCounter = 0;
	kingBedCounter = 0;
	matrimonialBedCounter = 0;
	cantidadCamas = 0;

	

	// TODO: REVISAR EN EL NGONINIT SI ES FAVORITO DEL USUARIO

	constructor(
		private _translate: TranslationService,
		private translateService: TranslateService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _activatedRoute: ActivatedRoute,
		private _cdr: ChangeDetectorRef,
		private storeFilters: StoreFiltersService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this._cdr.detectChanges();
	}

	ngOnInit() {
		this._translate.onLangChange.subscribe(() => {
			this.setAccomodationType();
		});
		this.setAccomodationType();
		this._changeDetectorRef.detectChanges();

		this.getCantidadNoches();
	}

	setAccomodationType() {
		const descriptionToTranslationKey: { [key: string]: string } = {
			Apartamentos: 'card.labelApartment',
			Casas: 'card.labelHouse',
			BedBreakfast: 'card.labelBedAndBreakFast',
			Suite: 'card.labelSuite',
		};
		const bedTypesToTranslationKey: { [key: string]: string } = {
			"camaQueen": "filtersSideMenu.textQueenBed",
			"camaKing": "filtersSideMenu.textKingBed",
			"camaSimple": "filtersSideMenu.textIndividualBed",
			"camaMatrimonial": "filtersSideMenu.textMatrimonialBed",
		};

		if (this.card.propiedades.tipoPropiedad.descripcion.includes('BedBreakfast')) {
			this.showDescription = true;
			var bathTranslationKey = '';
			const bedTranslationKeys: { [key: string]: string } = {};


			this.card.ambientes.forEach(element => {
				//Guardamos las key que de los ambientes compartidos

				if (this.detectarPatron('ba(ñ|n)o', element.tiposAmbiente.descripcion) && !element.tiposAmbiente.esCompartido) {
					//Baño privado
					bathTranslationKey = 'filtersSideMenu.textPrivateBathroom';
				}
				else {
					bathTranslationKey = 'filtersSideMenu.textSharedBathroom';
				}

				if (this.detectarPatron('habitaci(o|ó)n', element.tiposAmbiente.descripcion)) {
					this.cantidadHabitaciones++;
				}

				//mappeamos el tipo de cama y contamos la cantidad de si se repite algo
				element.objetos.forEach((element2: any) => {
					if (this.detectarPatron('cama', element2.catalogoObjetos.descripcion)) {

						if (this.detectarPatron('queen', element2.catalogoObjetos.descripcion)) {
							this.queenBedCounter++;
							this.cantidadCamas++;
						}
						if (this.detectarPatron('king', element2.catalogoObjetos.descripcion)) {
							this.kingBedCounter++;
							this.cantidadCamas++;
						}
						if (this.detectarPatron('simple', element2.catalogoObjetos.descripcion)) {
							this.kingBedCounter++;
							this.cantidadCamas++;
						}
						if (this.detectarPatron('matrimonial', element2.catalogoObjetos.descripcion)) {
							this.matrimonialBedCounter++;
							this.cantidadCamas++;
						}
						if (bedTranslationKeys) {
							Object.keys(bedTranslationKeys).forEach(key => {
								this._translate.getTranslation(bedTranslationKeys[key]).subscribe((res: string) => {
									this.bedType += res + ', ';
								});
							});
							this.bedType = this.bedType.slice(0, -2); // Remove trailing comma and space
						}
					}
				});

			});


		}

		const translationKey =
			descriptionToTranslationKey[
			this.card.propiedades.tipoPropiedad.descripcion
			];
		if (translationKey) {
			this._translate
				.getTranslation(translationKey)
				.subscribe((res: string) => {
					this.accomodationType = res;
				});
		}
	}

	private detectarPatron(patron: string, texto: string) {
		const regex = new RegExp(patron, 'i');
		return regex.test(texto);
	}

	getDateFormat(date: string) {
		const [year, month, day] = date.split('-').map(Number);
		return new Date(year, month - 1, day);
	}

	getCantidadNoches() {
		this.fromDate = this.storeFilters.getFilter().fromDate;
		this.toDate = this.storeFilters.getFilter().toDate;
		// Convertir las cadenas de texto a objetos Date
		let checkIn = new Date(this.fromDate);
		let checkOut = new Date(this.toDate);

		// Si checkOut es exactamente a medianoche, ajusta la fecha
		if (checkOut.getHours() === 0 && checkOut.getMinutes() === 0 && checkOut.getSeconds() === 0) {
			checkOut = new Date(checkOut.getTime()); // Resta 1 ms para considerar el día anterior
		}

		const diffTime = checkOut.getTime() - checkIn.getTime();
		const diffDays = diffTime / (1000 * 60 * 60 * 24);

		if (isNaN(diffDays) || diffDays < 0) {
			console.error('error de fechas');
			this.cantidadNoches = 1;
		} else {
			this.cantidadNoches = Math.floor(diffDays);
		}
	}

	getValoresCard() {
		const valores = {
			cantidadNoches: this.cantidadNoches,
			tarifaLunaNueva: Number(this.tarifaLunaNueva).toFixed(2),
			precioTotalNoches: this.precioTotalNoches.toFixed(2),
			tarifaLimpieza: Number(this.tarifaLimpieza).toFixed(2),
			precioPorNoche: Number(this.precioPorNoche).toFixed(2),
			total: this.total,
			accomodationType: this.accomodationType,
			totalConDescuento: this.totalConTodo.toFixed(2),
		};
		return valores
	}
}
