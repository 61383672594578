import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GoogleMap, MapInfoWindow, MapMarker, GoogleMapsModule } from '@angular/google-maps';

import { environment } from "../../../../environments/environment";
import { PropertyPostModel } from "../../../core/domain/property-post-model";
import { TranslationService } from "../../services/translation.service";
import { GoogleMapsLoaderService } from '../../services/googleMapsLoader.service';
import { AlojamientoFiltersModel, AlojamientosResponseModel } from '../../../core/domain/mapa/alojamiento-filters-model';
import { AlojamientosDisponiblesFiltersEntity } from '../../../data/entity/mapa/alojamientos-filters-entity';
import { AlojamientosFilters } from '../../interfaces/alojamientos-filters.interface';
import { tipoPropiedad } from '../../components/cards/cards.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'ln-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapComponent {


    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
    @ViewChild(GoogleMap, { static: false }) map!: google.maps.Map;

    @Input() center: google.maps.LatLng | google.maps.LatLngLiteral = { lat: 15.783471, lng: -90.230759 };
    @Input() height: string = '600px';
    @Input() posts: any[] = [];
    @Input() zoom: number = 8;
    @Input() isPropertyDetails: boolean = false;
    @Input() resetMap: boolean = false; 

    @Output() changedBounds = new EventEmitter<google.maps.LatLngBounds | undefined>();
    @Output() liked = new EventEmitter<void>();

    

    apiLoaded$!: Observable<boolean>;

    postSelected: AlojamientoFiltersModel | undefined = undefined;

    infoWindowsOptions: google.maps.InfoWindowOptions = {
        disableAutoPan: true
    }
    options: google.maps.MapOptions = {
        maxZoom: 20,
        minZoom: 8,
        streetViewControl: false,
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }]
            }
        ]
    };
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        zIndex: 10,
    };

    timer: number | NodeJS.Timeout | undefined;
    private apiLoaded = false;
    transBedBreakfast: any;
    translation: any;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _currencyPipe: CurrencyPipe,
        private _httpClient: HttpClient,
        private _translate: TranslationService,
        private http: HttpClient,
        private googleMapsLoaderService: GoogleMapsLoaderService,
        private _translationService: TranslationService,
        private _translateService: TranslateService
    ) {
        this._apiLoading();

        this._translationService.onLangChange.subscribe(() => {
            this.closeInfoWindows();
            this.refresh();
          });
    }

    closeInfoWindows() {
        if (this.infoWindow) {
            this.infoWindow.close();
        } else {
            console.warn('infoWindow is not defined yet.');
        }
    }

    getIcon(price: number, displayIcon: boolean, tipo: any): string {
        let svgIcon = '';

        const house = `
          <g mask="url(#mask1_5_2)">
            <path d="M6.74984 16.2501H7.49984V21.5002C7.49984 22.3274 8.17259 23.0002 8.99984 23.0002H17.9999C18.8271 23.0002 19.4999 22.3274 19.4999 21.5002V16.2501H20.2499C20.3982 16.2501 20.5432 16.2061 20.6665 16.1237C20.7898 16.0413 20.8859 15.9242 20.9426 15.7871C20.9994 15.6501 21.0142 15.4993 20.9853 15.3539C20.9564 15.2084 20.885 15.0748 20.7801 14.9699L14.0301 8.2199C13.9605 8.15019 13.8778 8.09489 13.7869 8.05716C13.6959 8.01942 13.5983 8 13.4998 8C13.4013 8 13.3038 8.01942 13.2128 8.05716C13.1218 8.09489 13.0392 8.15019 12.9696 8.2199L6.21959 14.9699C6.11474 15.0748 6.04333 15.2084 6.01441 15.3539C5.98548 15.4993 6.00033 15.6501 6.05709 15.7871C6.11384 15.9242 6.20994 16.0413 6.33325 16.1237C6.45656 16.2061 6.60153 16.2501 6.74984 16.2501ZM11.9998 21.5002V17.7502H14.9999V21.5002H11.9998ZM13.4998 9.81065L17.9999 14.3106V17.7502L18.0006 21.5002H16.4999V17.7502C16.4999 16.9229 15.8271 16.2501 14.9999 16.2501H11.9998C11.1726 16.2501 10.4998 16.9229 10.4998 17.7502V21.5002H8.99984V14.3106L13.4998 9.81065Z" fill="#577778"/>
          </g>
    
        `;

        const apartment = `
          <g>
            <path d="M18 8H10.5C9.67275 8 9 8.67275 9 9.5V14H7.5C6.67275 14 6 14.6727 6 15.5V22.25C6 22.4489 6.07902 22.6397 6.21967 22.7803C6.36032 22.921 6.55109 23 6.75 23H18.75C18.9489 23 19.1397 22.921 19.2803 22.7803C19.421 22.6397 19.5 22.4489 19.5 22.25V9.5C19.5 8.67275 18.8273 8 18 8ZM7.5 15.5H12V21.5H7.5V15.5ZM18 21.5H13.5V15.5C13.5 14.6727 12.8273 14 12 14H10.5V9.5H18V21.5Z" fill="#577778"/>
            <path d="M12 11H13.5V12.5H12V11ZM15 11H16.5V12.5H15V11ZM15 14.0233H16.5V15.5H15V14.0233ZM15 17H16.5V18.5H15V17ZM9 17.0008H10.5V18.5007H9V17.0008Z" fill="#577778"/>
          </g>
        `;
        const suite = `
            <g clip-path="url(#clip0_9892_19305)">
                <path d="M7 22.1133H20.5" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.5 22.1143V11.6143L14.5 8.61426V22.1143" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 22.1143V14.6143L14.5 11.6143" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.5 13.1143V13.1213" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.5 15.3643V15.3713" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.5 17.6133V17.6204" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.5 19.8633V19.8704" stroke="#577778" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.9701 12.0568C20.1239 12.0568 20.2713 12.1179 20.3799 12.2266C20.4886 12.3353 20.5497 12.4827 20.5497 12.6364C20.5497 12.4827 20.6108 12.3353 20.7194 12.2266C20.8281 12.1179 20.9755 12.0568 21.1292 12.0568C20.9755 12.0568 20.8281 11.9958 20.7194 11.8871C20.6108 11.7784 20.5497 11.631 20.5497 11.4773C20.5497 11.631 20.4886 11.7784 20.3799 11.8871C20.2713 11.9958 20.1239 12.0568 19.9701 12.0568ZM19.9701 8.57955C20.1239 8.57955 20.2713 8.64061 20.3799 8.74929C20.4886 8.85798 20.5497 9.00539 20.5497 9.15909C20.5497 9.00539 20.6108 8.85798 20.7194 8.74929C20.8281 8.64061 20.9755 8.57955 21.1292 8.57955C20.9755 8.57955 20.8281 8.51849 20.7194 8.4098C20.6108 8.30112 20.5497 8.15371 20.5497 8C20.5497 8.15371 20.4886 8.30112 20.3799 8.4098C20.2713 8.51849 20.1239 8.57955 19.9701 8.57955ZM17.9417 12.0568C17.9417 11.5957 18.1249 11.1535 18.451 10.8274C18.777 10.5014 19.2193 10.3182 19.6804 10.3182C19.2193 10.3182 18.777 10.135 18.451 9.80895C18.1249 9.48289 17.9417 9.04066 17.9417 8.57955C17.9417 9.04066 17.7586 9.48289 17.4325 9.80895C17.1064 10.135 16.6642 10.3182 16.2031 10.3182C16.6642 10.3182 17.1064 10.5014 17.4325 10.8274C17.7586 11.1535 17.9417 11.5957 17.9417 12.0568Z" stroke="#577778" stroke-width="0.409091" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            `;
        const bnb = `
              <g clip-path="url(#clip1_2_2)">
                <mask id="mask0_2_2" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="18">
                  <path d="M24 7H6V25H24V7Z" fill="white" />
                </mask>
                <g mask="url(#mask0_2_2)">
                  <path d="M8.25 17.4999C8.8725 17.9814 9.80775 18.2627 10.875 18.2499C11.9423 18.2627 12.8775 17.9814 13.5 17.4999C14.1225 17.0184 15.0577 16.7372 16.125 16.7499C17.1923 16.7372 18.1275 17.0184 18.75 17.4999" stroke="#577778" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.9999 9.25C11.7625 9.42038 11.5702 9.64598 11.4395 9.90732C11.3089 10.1687 11.2438 10.4579 11.2499 10.75C11.2438 11.0421 11.3089 11.3313 11.4395 11.5927C11.5702 11.854 11.7625 12.0796 11.9999 12.25" stroke="#577778" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.9999 9.25C14.7625 9.42038 14.5702 9.64598 14.4395 9.90732C14.3089 10.1687 14.2438 10.4579 14.2499 10.75C14.2438 11.0421 14.3089 11.3313 14.4395 11.5927C14.5702 11.854 14.7625 12.0796 14.9999 12.25" stroke="#577778" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.25 14.5H18.75V18.25C18.75 19.4435 18.2759 20.5881 17.432 21.432C16.5881 22.2759 15.4435 22.75 14.25 22.75H12.75C11.5565 22.75 10.4119 22.2759 9.56802 21.432C8.72411 20.5881 8.25 19.4435 8.25 18.25V14.5Z" stroke="#577778" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M18.5596 19.5443C18.8945 19.6981 19.2621 19.7672 19.63 19.7457C19.998 19.7241 20.3549 19.6124 20.6696 19.4206C20.9843 19.2287 21.247 18.9625 21.4347 18.6453C21.6224 18.3281 21.7293 17.9697 21.746 17.6015C21.7627 17.2333 21.6887 16.8667 21.5305 16.5338C21.3723 16.2009 21.1348 15.912 20.8387 15.6924C20.5427 15.4728 20.1973 15.3293 19.8329 15.2745C19.4684 15.2197 19.0961 15.2553 18.7486 15.3781" stroke="#577778" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </g>
              `;
        // SVG para bed&breakfast
        if (tipo === 'BedBreakfast') {
            svgIcon = bnb;
        } else if (tipo === 'Apartamentos') {
            svgIcon = apartment;
        } else if (tipo === 'Casas') {
            svgIcon = house;
        } else if (tipo === 'Suite') {
            svgIcon = suite;
        }



        const tagSvgRaw = (price: number) => `
          <svg width="109" height="32" viewBox="0 0 109 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_2)">
              <path d="M101 0.5H8C3.85786 0.5 0.5 3.85786 0.5 8V24C0.5 28.1421 3.85786 31.5 8 31.5H101C105.142 31.5 108.5 28.1421 108.5 24V8C108.5 3.85786 105.142 0.5 101 0.5Z" fill="white" />
              <text x="50%" y="22" text-anchor="middle" fill="#000000" font-size="14px" font-family="sans-serif" font-weight="bold"> ${this._currencyPipe.transform(price, 'USD')} </text>
              <path d="M101 0.5H8C3.85786 0.5 0.5 3.85786 0.5 8V24C0.5 28.1421 3.85786 31.5 8 31.5H101C105.142 31.5 108.5 28.1421 108.5 24V8C108.5 3.85786 105.142 0.5 101 0.5Z" stroke="#79747E" />
              <path d="M95.5 24C100.747 24 105 19.7467 105 14.5C105 9.25329 100.747 5 95.5 5C90.2533 5 86 9.25329 86 14.5C86 19.7467 90.2533 24 95.5 24Z" fill="#FFFEFE" />
              ${svgIcon}
            </g>
            <defs>
              <clipPath id="clip0_2_2">
                <rect width="109" height="32" fill="white" />
              </clipPath>
              <clipPath id="clip1_2_2">
                <rect width="18" height="18" fill="white" transform="translate(6 7)" />
              </clipPath>
            </defs>
          </svg>
        `;

        return this._encodeSVG(tagSvgRaw(price));
    }

    openInfoWindow(marker: MapMarker, post: AlojamientoFiltersModel) {
        this.postSelected = post;
        this.infoWindow.open(marker);
        
        this._cdr.detectChanges();
        const closeIcon = document.getElementById('closeIcon');
        if (closeIcon) {
            closeIcon.addEventListener('click', () => {
                this.infoWindow.close();
            });
        }
        google.maps.event.addListener(this.infoWindow, 'domready', () => {
            // Acceder al div que contiene el infoWindow
            const iwOuter = document.querySelector('.gm-style-iw-d');
        
            if (iwOuter) {
                
                iwOuter.classList.add('info');
            }
        });
    }

    refresh() {
        //console.log('refresh');
        this.liked.emit();
        if (this.timer)
            clearTimeout(this.timer as unknown as number); // Use this if your code runs in both browser and Node.js environments
        this.timer = setTimeout(() => this.changedBounds.emit(this.map.getBounds()), 750)
    }

    setZIndex(marker: MapMarker, index: number) {
        marker.marker?.setZIndex(index);
        this._cdr.detectChanges();
    }

    trackByFn(index: number, element: AlojamientoFiltersModel) {
        return element.idAlojamiento;
    }


    private _apiLoading() {
        this.apiLoaded$ = from(this.googleMapsLoaderService.loadApi())
            .pipe(
                tap(() => {
                    this.options = {
                        disableDefaultUI: this.isPropertyDetails, // Desactiva los controles predeterminados
                        draggable: !this.isPropertyDetails,       // No permite arrastrar el mapa
                        scrollwheel: !this.isPropertyDetails,     // Desactiva el zoom con la rueda del mouse
                        disableDoubleClickZoom: this.isPropertyDetails, // No permite hacer zoom con doble clic
                        gestureHandling: this.isPropertyDetails? 'none':'auto' // Desactiva todos los gestos (toque, arrastre, zoom)
                    };
                    this.markerOptions.clickable = !this.isPropertyDetails;
                }),
                map(() => true),
                catchError(() => of(false)),
            );
    }


    private _encodeSVG(rawSvgString: string): string {
        const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;
        rawSvgString = rawSvgString
            .replace(/'/g, '"')
            .replace(/>\s+</g, '><')
            .replace(/\s{2,}/g, ' ');

        return `data:image/svg+xml;utf-8,${rawSvgString.replace(symbols, encodeURIComponent)}`
    }


    parse(coordinate: any) {
        return parseFloat(coordinate);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['resetMap'] && changes['resetMap'].currentValue) {
            this.resetMapData();
        }
    }
    resetMapData() {
        this.posts = [];
        this.postSelected = undefined;
        this._cdr.detectChanges();
        // Aquí puedes agregar cualquier lógica adicional para reiniciar el mapa
    }
}
